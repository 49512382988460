import { ScreenType } from "@/models/common";
import { Language } from "@/models/donation";
import { createStore, Module } from "vuex";
import { DonationModule, MainModule } from "@/models/store-modules";
import { initialScreenSize } from "@/controllers/common";

const donationsStore: Module<DonationModule, MainModule> = {
  namespaced: true,
  state: () => ({
    country: "CO",
    translation: "es" as Language,
  }),
  mutations: {
    updateCountry(state, country: string) {
      state.country = country;
    },
    updateTranslation(state, translation: Language) {
      state.translation = translation;
    },
  },
  actions: {
    changeCountry(context, country: string) {
      context.commit("updateCountry", country);
    },
    changeTranslation(context, translation: Language) {
      context.commit("updateTranslation", translation);
    },
  },
  getters: {
    country(state) {
      return state.country;
    },
    translation(state) {
      return state.translation;
    },
  },
};

export default createStore<MainModule>({
  state: {
    device: initialScreenSize(),
    prodHost: "https://legacy-api.centrocristiano.com.co",
    devHost: "",
    // devHost: "https://centro-cristiano-backend.herokuapp.com",
    // devHost: "http://192.168.1.7:3005",
  },
  getters: {
    deviceType(state) {
      return state.device;
    },
    productionHost(state) {
      return state.prodHost;
    },
    developmentHost(state) {
      return state.prodHost;
    },
  },
  mutations: {
    updateDevice(state, device: ScreenType) {
      state.device = device;
    },
  },
  actions: {
    changeDevice(context, device: ScreenType) {
      context.commit("updateDevice", device);
    },
  },
  modules: {
    donations: donationsStore,
  },
});
